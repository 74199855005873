import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import Container from "../components/container"
import useImageMap from "../hooks/imagemap"
import Img from "gatsby-image"
import { FacultyCard } from "./allfacultytemplate"
import Row from "../components/row"
import FlHdDiv from "../components/flhddiv"
import Tabs from "../components/tabs/tabs"
import PageLayout from "../layouts/pagelayout"

const EMPTY_QUERY = ""

// const LabCard = ({ lab, imageMap }) => {
//   const [hover, setHover] = useState(false)

//   const { paths } = useSiteMetadata()

//   const onMouseEnter = (e) => {
//     setHover(true)
//   }

//   const onMouseLeave = (e) => {
//     setHover(false)
//   }

//   let img

//   if (lab.id in imageMap) {
//     img = (
//       <GatsbyImage
//         image={getImage(imageMap[lab.id]}
//         className="w-full h-full"
//       />
//     )
//   } else {
//     img = <img src={genericsvg} className="w-full" alt={lab.name} />
//   }

//   return (
//     <div
//       className={`w-full mb-16 trans-ani`}
//       onMouseEnter={onMouseEnter}
//       onMouseLeave={onMouseLeave}
//     >
//       <Link to={`${paths.labsPath}/${lab.id}`}>
//         <div
//           className={`w-full overflow-hidden bg-white trans-ani ${
//             hover ? "opacity-100" : "opacity-90"
//           }`}
//         >
//           <ZoomImage
//             image={getImage(
//               lab.id in imageMap
//                 ? imageMap[lab.id]
//                 : imageMap["generic"]
//             }
//             className="trans-ani"
//             extZoom={hover}
//           />
//         </div>
//         <div
//           className={`m-1 mt-4 trans-ani ${
//             hover ? "text-columbia-secondary-blue" : ""
//           }`}
//         >
//           <h4>{lab.name}</h4>
//         </div>
//       </Link>
//     </div>
//   )
// }

type LabGridProps = {
  labs: Array<any>
  colWidth?: string
  imageMap?: any
}

const LabGrid: React.FC<LabGridProps> = ({ labs, colWidth, imageMap }) => {
  const ret: any = []

  labs.map((lab: any, index: number) => {
    ret.push(
      <div className={`${colWidth} mr-4 mb-4`} key={`person-${index}`}>
        <FacultyCard person={lab.faculty} imageMap={imageMap} />
      </div>
    )
  })

  return (
    <Row wrap={true} isCentered={false} isVCentered={false} className="w-full">
      {ret}
    </Row>
  )
}

LabGrid.defaultProps = {
  colWidth: "w-full sm:w-9/20 lg:w-3/10 xl:w-22/100 3xl:w-18/100 4xl:w-3/20",
  imageMap: {},
}

type AllLabsTemplateProps = {
  path: string
  pageContext: any
  data: any
}

const AllLabsTemplate: React.FC<AllLabsTemplateProps> = ({
  path,
  pageContext,
  data,
}) => {
  const { allLabs, crumbs } = pageContext

  const [query, setQuery] = useState(EMPTY_QUERY)
  const [filteredGroups, setFilteredGroups] = useState([])
  const [page, setPage] = useState(1)
  const [recordsPerPage, setRecordsPerPage] = useState(20)

  const imageMap = useImageMap(data)

  // const handleInputChange = e => {
  //   const q = e.target.value.toLowerCase()
  //   let ret = []

  //   // for (let group of allGroups) {
  //   //   if (group.frontmatter.name.toLowerCase().includes(q)) {
  //   //     ret.push(group)
  //   //   }
  //   // }

  //   // update state according to the latest query and results
  //   setQuery(q)
  //   setFilteredGroups(ret)
  //   setPage(1)
  // }

  // const onPageChanged = data => {
  //   const { page } = data
  //   setPage(page)
  // }

  // const hasSearchResults = query !== EMPTY_QUERY
  // let groups = hasSearchResults ? filteredGroups : allGroups

  // const offset = (page - 1) * recordsPerPage
  // let pagedGroups = groups.slice(offset, offset + recordsPerPage)

  return (
    <PageLayout
      path={path}
      crumbs={crumbs}
      title="Research Labs"
      index={false}
      // titleContent={
      //   <SearchSummary count={groups.length} single="Lab" plural="Labs" />
      // }
    >
      {/* <SearchBar
        handleInputChange={handleInputChange}
        placeholder="Search labs"
        text={query}
        className="my-4"
      /> */}
      <FlHdDiv>
        <Container>
          <Tabs>
            <div id="Institute">
              <LabGrid
                labs={allLabs.filter((lab: any) => {
                  return lab.faculty.frontmatter.groups.includes(
                    "faculty::Director"
                  )
                })}
                imageMap={imageMap}
              />

              <LabGrid
                labs={allLabs.filter((lab: any) => {
                  return lab.faculty.frontmatter.groups.includes(
                    "faculty::Members"
                  )
                })}
                imageMap={imageMap}
              />
            </div>
            <div id="Associate Members">
              <LabGrid
                labs={allLabs.filter((lab: any) => {
                  return lab.faculty.frontmatter.groups.includes(
                    "faculty::Associate Members"
                  )
                })}
                imageMap={imageMap}
              />
            </div>
          </Tabs>
        </Container>
      </FlHdDiv>
    </PageLayout>
  )
}

export default AllLabsTemplate

export const query = graphql`
  query {
    images: allFile(
      filter: {
        absolutePath: { regex: "/images/people/" }
        ext: { regex: "/jpg/" }
      }
    ) {
      edges {
        node {
          name
          ext
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    genericPersonImage: file(absolutePath: { regex: "/generic.png/" }) {
      name
      ext
      relativePath
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, aspectRatio: 1)
      }
    }
  }
`
